import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFileContract } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { TableCheckbox } from '@/shared/components/MaterialTableElements';
import { eshopStatusLabels, eshopStatusColors, eshopBillingStatusColors, eshopBillingStatusLabels } from '@/utils/utilities';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { successNotify, customSuccessNotify, customWarningNotify } from '../../App/Router';
import { sendStandbyOptinRequest } from '../actions/sendStandbyOptinRequest';
import { toggleEshopVisioned } from '../actions/toggleEshopVisioned';

const StandbyRegistrationItemList = ({ item, isSelected = false, handleSelected = false, selectedList = false, refreshTable }) => {

  const dispatch = useDispatch();
  const [toRefresh, setToRefresh] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState(isSelected);
  const [pendingDocs, setPendingDocs] = useState(item.pending_docs);
  const [showOptin, setShowOptin] = useState(!item.optin);
  const [showPendingDocs, setShowPendingDocs] = useState(item.data.structuredData?.other_domain);

  const handleSelectItem = () => {
    if (handleSelected != false) {
      handleSelected(selectedList => ({
        ...selectedList,
        [item.eshop_id]: !isItemSelected
      }));
    }
    setIsItemSelected(!isItemSelected);
  }

  const handleSendOptin = (id) => {
    const data = { ids: [id] };
    dispatch(sendStandbyOptinRequest({ data: data })).then(() => {
      customSuccessNotify('Richiesta inviata con successo');
    });
  }

  const handleTogglePendingDocs = (id) => {
    const value = (pendingDocs == 1) ? 0 : 1;
    dispatch(toggleEshopVisioned({ id: id, value: value })).then(() => {
      successNotify();
      setPendingDocs(value)
      setToRefresh(true)
    });
  }

  const toLowerCase = (str) => {
    return str.toLowerCase();
  }

  useEffect(() => {
    if (showOptin) {
      if (handleSelected != false) {
        handleSelected(selectedList => ({
          ...selectedList,
          [item.id]: isSelected
        }));
      }
    }
    setIsItemSelected(isSelected);
  }, [isSelected, toRefresh]);

  return (
    <>
      <tr key={item.id}>
        <td>
          {showOptin && (
            <TableCheckbox checked={isItemSelected} onChange={() => handleSelectItem()} />
          )}
        </td>
        <td className="first-letter-uppercase">
          {item.domain}
        </td>
        <td className="first-letter-uppercase">
          <Badge bg={item.plan == 'ibrido' ? 'warning' : 'success'}>{item.plan}</Badge>
        </td>
        <td>
          {(item.data.b2bUser?.name) ? item.data.b2bUser.name : '-'}
        </td>
        <td>
          {(item.email) ? item.email : '-'}
        </td>
        <td>
          {(item.data.b2bUser?.phone) ? item.data.b2bUser.phone : '-'}
        </td>
        <td>
          {moment(item.creation_date).format('DD/MM/YYYY HH:mm')}
        </td>
        <td>
          {showPendingDocs ? (
            <Button variant={(pendingDocs == 1) ? 'danger' : 'success'} size="sm" onClick={() => handleTogglePendingDocs(item.eshop_id)}>
              <FontAwesomeIcon icon={faFileContract} />
            </Button>
          ) : '-'}
        </td>
        <td>
          {showOptin ? (
            <Button variant="warning" size="sm" onClick={() => handleSendOptin(item.eshop_id)}>
              <FontAwesomeIcon icon={faEnvelope} />
            </Button>
          ) : '-'}
        </td>
      </tr >
    </>
  );
};

export default StandbyRegistrationItemList;