import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { colorAccent } from '@/utils/palette';
import { getEshopInvitations } from './actions/getEshopInvitations';
import EshopInvitationItemList from './components/EshopInvitationItemList';
import PaginationTable from '@/containers/Components/PaginationTable';

const InvitationList = ({ esa_id = false }) => {
  const dispatch = useDispatch();
  const [toRefresh, setToRefresh] = useState(false);
  const dataState = useSelector(state => state.eshops.eshopInvitations)
  const dataItems = dataState.entities;
  const loading = dataState.loading;
  const pagination = dataState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  const tableLabels = esa_id
    ? ['Data', 'Caricati', 'Generati', 'Utilizzati', 'Stato']
    : ['Eshop', 'piano', 'Data', 'Caricati', 'Generati', 'Utilizzati', 'Stato'];

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
    }
    const params = esa_id ? { 'per-page': 25, page: 1, eshop_id: esa_id } : { 'per-page': 25, 'page': 1 };
    dispatch(getEshopInvitations({ params: params }));
  }, [dispatch, toRefresh]);

  return (
    <Card>
      <CardBody>
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="page-title">Gestione inviti massivi</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <CardTitleWrap>
                    <Row className='align-items-center'>
                      <Col xs={12} lg={4}>
                        <p className="page-subtitle">Numero elementi: {pagination?.total_count}</p>
                      </Col>
                    </Row>
                  </CardTitleWrap>
                  {loading === 'loading' ? (
                    <div className="text-center">
                      <TableSpinner animation="border" />
                    </div>
                  ) : (
                    <>
                      <Table responsive striped bordered>
                        <thead>
                          <tr>
                            {tableLabels.map((label) => {
                              return <th>{label}</th>
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {dataItems && loading === 'idle' && dataItems.length > 0 && (
                            dataItems.map((elem) => {
                              return (<EshopInvitationItemList item={elem} setToRefreshList={setToRefresh} showEshop={esa_id ? false : true} />)
                            })
                          ) || (
                              <tr>
                                <td colSpan={tableLabels.length} className='text-start'>Nessun elemento trovato</td>
                              </tr>
                            )}
                        </tbody>
                      </Table>
                      {dataItems && dataItems.length > 0 && (
                        <PaginationTable pagination={pagination} action={getEshopInvitations} />
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};

InvitationList.propTypes = {
  onSubmit: PropTypes.func,
};

export default InvitationList;