import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../config/api';

export const toggleEshopVisioned = createAsyncThunk(
    'eshop/toggleEshopVisioned',
    async ({ id, value }) => {
        let url = `/v1/set-pending-docs/${value}/${id}`
        const response = await api.get(url);
        return response.data;
    }
);