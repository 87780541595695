import ExcelJS from 'exceljs';

export const getOidcStorageKey = () => {
  const authSettings = JSON.parse(localStorage.getItem('authSettings'));
  if (authSettings) {
    return `oidc.user:${authSettings.auth_server}:${authSettings.client_id}`;
  }
  return null;
};

export const getOidcInfo = () => {
  const key = getOidcStorageKey();
  if (key) {
    const oidc = JSON.parse(localStorage.getItem(key));
    return oidc;
  }
  return null;
};

export const getToken = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    return oidc.id_token;
  }
  return null;
};

const getDay = (date) => {
  if (!date) return '';
  return `${new Date(date)?.getDate()}`;
};

const getValue = (price) => {
  if (Number.isNaN(price)) return 0;
  return Number(price).toFixed(2);
};

const getNameAndValueArr = history => history.map(day => ({
  name: getDay(day?.date),
  value: getValue(day.priceUsd),
}));

export const getWeekChartData = (cryptoHistory) => {
  if (!cryptoHistory) return null;

  const weekChartDataArr = cryptoHistory?.map(item => ({
    currency: item?.currency,
    history: getNameAndValueArr(item?.history.slice(7, item?.history.length)),
  }));

  return Object.fromEntries(weekChartDataArr.map(item => [item.currency, item.history]));
};

export const getShortCryptoAddress = value => `${value.slice(0, 5)}...${value.slice(-3)}`;


export const generateExcel = (headers, data, filename) => {

  // Import ExcelJS if not already imported at the top of the file
  const ExcelJS = require('exceljs');

  // Validate inputs
  if (!headers || !Array.isArray(headers) || !data || !Array.isArray(data)) {
    throw new Error('Invalid headers or data provided');
  }

  // Validate that headers have required properties
  headers.forEach(header => {
    if (!header.label || !header.key) {
      throw new Error('Headers must have label and key properties');
    }
  });
  const csvData = data /*data.map((value, index) => ({
    [index]: value
  }));*/

  const options = {
    filename: filename,
    headers: headers,
    data: csvData
  };

  const excel = new ExcelJS.Workbook();
  const worksheet = excel.addWorksheet('Orders');

  worksheet.columns = headers.map(header => ({
    header: header.label,
    key: header.key,
    width: 20
  }));

  worksheet.addRows(csvData);

  excel.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = options.filename;
    link.click();
    window.URL.revokeObjectURL(url);
  });
}