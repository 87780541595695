import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import { FormContainer, FormGroup, FormGroupField, FormGroupLabel } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Col, Row } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import renderSelectField from '@/shared/components/form/Select';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import styled from 'styled-components';
import '../../css/filterEshopList.css';

const OrdersFilterForm = ({ onSubmit, showEshop = true }) => (
  <Card>
    <CardBody>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <FormContainer onSubmit={handleSubmit} className="filterForm">
            <Row>
              {showEshop && (
                <Col>
                  <FormGroup>
                    <FormGroupLabel>Eshop</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="domain"
                        component="input"
                        type="text"
                        placeholder="Dominio eshop"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
              )}
              <Col>
                <FormGroup>
                  <FormGroupLabel>Email cliente</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="customer_email"
                      component="input"
                      type="text"
                      placeholder="Email cliente"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>N. Ordine</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="order_reference"
                      component="input"
                      type="text"
                      placeholder="N. Ordine"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Stato invio</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="send"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', label: 'Tutti' },
                        { value: '-1', label: 'Domini validi' },
                        { value: '0', label: 'Da inviare' },
                        { value: '1', label: 'Inviati' },
                        { value: '3', label: 'Bloccati' },
                        { value: '4', label: 'Domini Bloccati' },
                        { value: '5', label: 'Da inviare' },
                        { value: '6', label: 'Ordini ripetuti' },
                      ]}
                      placeholder={'Tutti'}
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Stato utilizzo</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="status"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', label: 'Tutti' },
                        { value: '1', label: 'Utilizzati' },
                        { value: '0', label: 'Da utilizzare' },
                      ]}
                      placeholder={'Tutti'}
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Stato reminder</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="reminder_send"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', label: 'Tutti' },
                        { value: '1', label: 'Inviati' },
                        { value: '0', label: 'Da inviare' },
                      ]}
                      placeholder={'Tutti'}
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel></FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="no_email"
                      component={renderCheckBoxField}
                      type="checkbox"
                      label="Inviti senza email"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel></FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="no_cell"
                      component={renderCheckBoxField}
                      type="checkbox"
                      label="Inviti senza telefono"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Data creazione</FormGroupLabel>
                  <FormGroupField>
                    <div className='d-inline-flex align-items-center'>
                      <Field
                        name="creation_date_start"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Dal"
                      />
                      <span className='mx-2'> - </span>
                      <Field
                        name="creation_date_end"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Al"
                      />
                    </div>
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Data di invio</FormGroupLabel>
                  <FormGroupField>
                    <div className='d-inline-flex align-items-center'>
                      <Field
                        name="send_date_start"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Dal"
                      />
                      <span className='mx-2'> - </span>
                      <Field
                        name="send_date_end"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Al"
                      />
                    </div>
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Data reminder</FormGroupLabel>
                  <FormGroupField>
                    <div className='d-inline-flex align-items-center'>
                      <Field
                        name="reminder_date_start"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Dal"
                      />
                      <span className='mx-2'> - </span>
                      <Field
                        name="reminder_date_end"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Al"
                      />
                    </div>
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Data utilizzo</FormGroupLabel>
                  <FormGroupField>
                    <div className='d-inline-flex align-items-center'>
                      <Field
                        name="use_date_start"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Dal"
                      />
                      <span className='mx-2'> - </span>
                      <Field
                        name="use_date_end"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Al"
                      />
                    </div>
                  </FormGroupField>
                </FormGroup>
              </Col>
            </Row>
            <Row className='text-end'>
              <Col>
                <FormGroup controlId="formButton">
                  <FilterButton
                    variant="outline-success"
                    size='sm'
                    onClick={handleSubmit}
                  >
                    <MagnifyIcon /><span>Filtra</span>
                  </FilterButton>
                </FormGroup>
              </Col>
            </Row>
          </FormContainer>
        )}
      </Form>
    </CardBody>
  </Card>
);

OrdersFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default OrdersFilterForm;

const FilterButton = styled(Button)`
    margin-top: calc(1.5em + .35rem);
`;
