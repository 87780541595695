import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { deleteReviewRatingModel } from '../actions/deleteReviewRatingModel';
import { updateReviewRatingModel } from '../actions/updateReviewRatingModel';
import ReviewRatingModelModal from './modals/ReviewRatingModelModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { successNotify, warningNotify } from '../../App/Router';

function ReviewRatingModelItem({ item, triggerRefresh }) {

  debugger
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [selectedElem, setSelectedElem] = useState(null);
  const detailRatings = item.detail_ratings ? item.detail_ratings : [];
  const optionalRatings = item.optional_ratings ? item.optional_ratings : [];
  const optionalDisplayValues = getOptionalRatingsDisplayValues(detailRatings, optionalRatings);

  function getOptionalRatingsDisplayValues(detailRatings, optionalRatings) {
    return optionalRatings
      .map(id => detailRatings[id])
      .filter(item => item !== undefined)
      .map(item => item.display_value);
  }

  const handleEditClick = (elem) => {
    setSelectedElem(elem);
    setShowUpdateModal(true);
  };

  const handleDeleteClick = (userId) => {
    setIdToDelete(userId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteReviewRatingModel({ id: idToDelete })).then((response) => {
      if (response.payload?.status == 'success') {
        handleClose();
        triggerRefresh();
        successNotify();
      } else {
        warningNotify();
      }

    });
  };

  const handleClose = () => {
    setShowDeleteModal(false);
  };

  const handleModalClose = () => {
    setShowUpdateModal(false);
  };

  const handleSave = (updatedElem) => {

    const data = {
      title: updatedElem.title,
      detail_ratings: updatedElem.detail_ratings
    }

    dispatch(updateReviewRatingModel({ id: updatedElem.id, data: data })).then((response) => {
      if (response.payload.status == 'error') {
        warningNotify();
      } else {
        successNotify();
        setShowUpdateModal(false);
        triggerRefresh();
      }
    });
  };

  return (
    <>
      <tr key={item.id} className={item.internal_name == 'default' ? 'bg-warning bg-gradient bg-opacity-50' : ''}>
        <td className="align-top first-letter-uppercase">{item.title}</td>
        <td className="align-top first-letter-uppercase">
          <ul className="list-group">
            {detailRatings ? (Object.entries(detailRatings).map(([id, rating]) => (
              <li className="list-group-item" key={id}>{rating.display_value}</li>
            ))) : (
              '-'
            )}
          </ul>
        </td>
        <td className="align-top first-letter-uppercase">
          <ul className="list-group">
            {optionalDisplayValues.length > 0 ? (
              optionalDisplayValues.map((value, index) => (
                <li className="list-group-item" key={optionalRatings[index]}>{value}</li>
              ))
            ) : (
              '-'
            )
            }
          </ul>
        </td>
        <td className="align-top first-letter-uppercase">{item.nReviews || '-'}</td>
        <td className="align-top first-letter-uppercase">{item.nEshops || '-'}</td>
        <td className="align-top first-letter-uppercase">
          <Button variant="primary" onClick={() => handleEditClick(item)} className='d-block mx-3 my-2 p-1'>
            <FontAwesomeIcon icon={faEdit} className='me-1 pe-1 border-end' />
            Modifica
          </Button>
          {item.nReviews == 0 && item.nEshops == 0 && item.internal_name != 'default' && (
            <Button variant="danger" onClick={() => handleDeleteClick(item.id)} className='d-block mx-3 my-2 p-1'>
              <FontAwesomeIcon icon={faTrashAlt} className='me-1 pe-1 border-end' />
              Elimina
            </Button>
          )}
        </td>
      </tr>
      <ReviewRatingModelModal
        showModal={showUpdateModal}
        handleClose={handleModalClose}
        handleSave={handleSave}
        item={selectedElem}
      />
      <Modal show={showDeleteModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma eliminazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sei sicuro di voler cancellare la recensione?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReviewRatingModelItem;