import { combineReducers } from 'redux';
import fetchReducer from './fetch';
import insertReducer from './insert';
import eshopImportsReducer from './eshopImports';
import eshopInvitationsReducer from './eshopInvitations';
import ordersReducer from './orders';
import paymentMethodsReducer from './paymentMethods';
import customReviewInvitations from './customReviewInvitations';
import getStandbyRegistration from './getStandbyRegistration';
//import detailReducer from './detail';

const eshopsReducer = combineReducers({
    fetch: fetchReducer,
    insert: insertReducer,
    eshopImports: eshopImportsReducer,
    eshopInvitations: eshopInvitationsReducer,
    paymentMethods: paymentMethodsReducer,
    orders: ordersReducer,
    customReviewInvitations: customReviewInvitations,
    standbyRegistration: getStandbyRegistration,
    //detail: detailReducer,
});

export default eshopsReducer;
