import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Badge, Form, Container, Row, Col, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClock, faCloudArrowDown, faCloudUpload, faCogs, faEye, faImage, faPen, faTimes, faTrash, faTriangleExclamation, faUpload } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { successNotify, warningNotify } from '../../App/Router';
import { handleMassiveInvitations } from '../actions/handleMassiveInvitations';
import { eshopInvitationStatusLabels, eshopInvitationStatusColors } from '@/utils/utilities';
import { Table } from '@/shared/components/TableElements';
import { generateExcel } from '@/utils/helpers';

const EshopInvitationItemList = ({ item, setToRefreshList, showEshop = true }) => {

  const dispatch = useDispatch();
  const [elem, setElem] = useState(item);
  const [showModal, setShowModal] = useState(false);
  const ordersList = elem.ordersInfo.orderList;
  const maxShowOrders = 100;

  const handleOpen = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const handleChangeStatus = (value) => {
    dispatch(handleMassiveInvitations({ id: elem.id, status: value })).then(() => {
      successNotify();
      setToRefreshList(true);
    });
  }

  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY')
  }

  function percentage(num, total) {
    if (total > 0) {
      return Math.round((num / total) * 100) + '%';
    }
    else {
      return '-';
    }
  }

  const getOrderList = () => {
    const headers = [
      { label: 'N.Ordine', key: 'order_reference' },
      { label: 'Email', key: 'customer_email' },
      { label: 'Cliente', key: 'customer_name' }
    ];

    const data = ordersList.map(item => ({
      order_reference: item.order_reference,
      customer_email: item.customer_email,
      customer_name: item.customer_name
    }));

    generateExcel(headers, data, 'ordini-' + elem.eshopInfo.domain + '.xlsx');

  }

  useEffect(() => {
    setElem(item);
  }, [item]);

  return (
    <>
      <tr key={elem.id} >
        {showEshop && (
          <>
            <td>
              <Link to={(elem.eshopInfo.domain == "ibrido") ? '/hibrid-eshops/update/' : '/registered-eshops/update/' + elem.eshop_id}>
                {elem.eshopInfo.domain}
              </Link>
            </td>
            <td className="first-letter-uppercase">
              <Badge bg={elem.eshopInfo.plan == 'ibrido' ? 'warning' : 'success'}>{elem.eshopInfo.plan}</Badge>
            </td>
          </>
        )}
        <td>
          {elem.date ? formatDate(elem.date) : '-'}
        </td>
        <td>
          {elem.invitations_uploaded ? elem.invitations_uploaded : '-'}
        </td>
        <td>
          {elem.ordersInfo.total ? elem.ordersInfo.total + '(' + percentage(elem.ordersInfo.total, elem.invitations_uploaded) + ')' : '-'}
        </td>
        <td>
          {elem.ordersInfo.used ? elem.ordersInfo.used + '(' + percentage(elem.ordersInfo.used, elem.invitations_uploaded) + ')' : '-'}
        </td>
        <td>
          <Button
            variant={eshopInvitationStatusColors[elem.status]}
            size='sm'
            onClick={handleOpen}
          >
            {eshopInvitationStatusLabels[elem.status]}
          </Button>
        </td>
      </tr >
      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Opzioni caricamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='my-3 text-center border-bottom pb-3'>
              {elem.ordersInfo.total > 0 ? (
                <>
                  {elem.status == 0 && (
                    <Col xs={12} lg={6} className='my-3'>
                      <Button onClick={() => handleChangeStatus(2)} size='sm' variant={'success'} className='me-2'>
                        <FontAwesomeIcon icon={faCheck} className='me-2' />
                        Approva caricamento
                      </Button>
                    </Col>
                  )
                  }
                </>
              ) : (
                <Col xs={12} lg={6} className='my-3'>
                  <Button size='sm' variant={'danger'} className='me-2' size={'sm'}>
                    <FontAwesomeIcon icon={faTriangleExclamation} className='me-2' />
                    Nessun invito generato
                  </Button>
                </Col>
              )}
              {elem.status == 0 && (
                <Col xs={12} lg={6} className='my-3'>
                  <Button onClick={() => handleChangeStatus(3)} size='sm' variant={'danger'} className='me-2'>
                    <FontAwesomeIcon icon={faTimes} className='me-2' />
                    Rifiuta caricamento
                  </Button>
                </Col>
              )}
            </Row>
            <Row className='my-3'>
              <Col xs={12}>
                {ordersList && (
                  <>
                    <Col xs={12} className='my-3 justify-content-center align-items-center'>
                      <h3 className='text-center'>
                        Mostrati {ordersList.length > maxShowOrders ? maxShowOrders : ordersList.length} di {ordersList.length} ordini
                      </h3>
                    </Col>
                    <Col xs={12} className='my-3 text-center'>
                      <Button onClick={() => getOrderList()} size='sm' variant={'primary'} className='ms-2' size={'sm'}>
                        <FontAwesomeIcon icon={faCloudArrowDown} className='me-2' />
                        Scarica lista completa
                      </Button>
                    </Col>
                  </>
                )}
                {ordersList ? (
                  <Table responsive striped bordered>
                    <thead>
                      <tr>
                        <th>N.Ordine</th>
                        <th>Email</th>
                        <th> Cliente </th>
                      </tr>
                    </thead>
                    {ordersList.slice(0, maxShowOrders).map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {item.order_reference}
                          </td>
                          <td>
                            {item.customer_email}
                          </td>
                          <td>
                            {item.customer_name}
                          </td>
                        </tr>
                      )
                    })}
                  </Table>
                ) : (
                  <>
                    <h4 className='text-success'>
                      <FontAwesomeIcon icon={faCheck} className='me-2' /> Nessun Invito
                    </h4>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body >
      </Modal >
    </>
  );
};

export default EshopInvitationItemList;