import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import { FormContainer, FormGroup, FormGroupField, FormGroupLabel } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Col, Row } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import renderSelectField from '@/shared/components/form/Select';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import styled from 'styled-components';
import '../../css/filterEshopList.css';

const StandbyRegistrationFilterForm = ({ onSubmit }) => (
  <Card>
    <CardBody>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <FormContainer onSubmit={handleSubmit} className="filterForm">
            <Row>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Dominio</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="domain"
                      component="input"
                      type="text"
                      placeholder="Dominio"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Email</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="email"
                      component="input"
                      type="text"
                      placeholder="Email"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Piano eshop</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="plan"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', 'label': 'Tutti i piani' },
                        // { value: 'hibrid', label: 'Ibridi' },
                        { value: 'free', label: 'Free' },
                        { value: 'basic', label: 'Basic' },
                        { value: 'lite', label: 'Lite' },
                        { value: 'pro', label: 'Pro' },
                        { value: 'company', label: 'Company' }
                      ]}
                      placeholder={'Tutti i piani'}
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Optin</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="optin"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', label: 'Tutti' },
                        { value: '1', label: 'Eseguito' },
                        { value: '0', label: 'Non eseguito' },
                      ]}
                      placeholder={'Tutti'}

                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Documentazione</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="pending_docs"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', label: 'Tutti' },
                        { value: '0', label: 'Visionati' },
                        { value: '1', label: 'Da visionare' },
                      ]}
                      placeholder={'Tutti'}

                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Data di registrazione</FormGroupLabel>
                  <FormGroupField>
                    <div className='d-inline-flex align-items-center'>
                      <Field
                        name="creation_date_start"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Dal"
                      />
                      <span className='mx-2'> - </span>
                      <Field
                        name="creation_date_end"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Al"
                      />
                    </div>
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup controlId="formButton">
                  <FilterButton
                    variant="outline-success"
                    size='sm'
                    onClick={handleSubmit}
                  >
                    <MagnifyIcon /><span>Filtra</span>
                  </FilterButton>
                </FormGroup>
              </Col>
            </Row>
          </FormContainer>
        )}
      </Form>
    </CardBody>
  </Card>
);

StandbyRegistrationFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default StandbyRegistrationFilterForm;

const FilterButton = styled(Button)`
    margin-top: calc(1.5em + .35rem);
`;
