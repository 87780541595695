import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row, Spinner, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { cleanDate } from '@/shared/helpers';
import { colorAccent } from '@/utils/palette';
import { fetchListPromo } from './actions/listPromo';
import PromoItemList from './components/PromoItemList';
import PaginationTable from '@/containers/Components/PaginationTable';
import { Modal } from 'react-bootstrap';
import { customWarningNotify, successNotify, warningNotify, customSuccessNotify } from '../App/Router';
import { postPromo } from './actions/createPromo';
import endpoints from './endpoints/plans';
import { api } from '@/config/api';

const PromoList = ({ esa_id = false }) => {

  const dispatch = useDispatch();

  const listPromoState = useSelector(state => state.plans.listPromo)
  const [toRefreshTable, setToRefreshTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [interactedItem, setInteractedItem] = useState({
    item: null,
    action: '',
  });
  const [globalFilters, setGlobalFilters] = useState({ 'per-page': 25, page: 1 });
  const [modalProperties, setModalProperties] = useState({
    title: null,
    content: null
  });

  const defaultPromo = {
    name: null,
    stripe_id: null,
    activation_cost: null,
    invitations_bonus: null,
    duration: 1,
    duration_in_months: null,
    amount_off: null,
    percent_off: null,
    paypal_enabled: null,
    wire_transfer_enabled: null,
    plans: {
      lite_m: null,
      list_s: null,
      lite_a: null,
      pro_m: null,
      pro_s: null,
      pro_a: null,
      company_m: null,
      company_s: null,
      company_a: null,
    }
  }
  const [newPromo, setNewPromo] = useState(defaultPromo);
  const [showPromoDurationMonths, setShowPromoDurationMonths] = useState(false);
  const [showCompanyPlus, setShowCompanyPlus] = useState(false);

  const listPromo = listPromoState.entities;
  const loading = listPromoState.loading;
  const pagination = listPromoState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;

  const tableLabels = [
    'Nome promo',
    'Codice promo',
    'Data di creazione',
    'Ultimo ggiornamento',
    'Sconto',
    'Costo attivazione',
    'Validità promo',
    'PayPal',
    'Bonifico',
    'Link',
    'Elimina'
  ];

  const handleOpen = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setNewPromo((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
  };

  const showLinks = (links) => {
    var registration_links_title = 'Link registrazione';
    var upgrade_links_title = 'Link upgrade da free';

    const PromoLinksItemLi = styled.li`
      margin-bottom: 10px;
      font-size: 1.2em;
      :not(:last-child) {
        margin-bottom: 10px;
      }
    `;

    const PromoLinksItemUl = styled.ul`
      list-style: none;
      padding: 0;
      margin-top: 15px;
      margin-bottom: 25px;
    `;

    var registration_listed_links = links['registrazione'].map((link) => {
      return (<PromoLinksItemLi>{link}</PromoLinksItemLi>)
    });

    var upgrade_listed_links = links['upgrade'].map((link) => {
      return (<PromoLinksItemLi>{link}</PromoLinksItemLi>)
    });

    return (
      <>
        <Row>
          <Col xs={12}>
            <h2>{registration_links_title}</h2>
            <PromoLinksItemUl>{registration_listed_links}</PromoLinksItemUl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h2>{upgrade_links_title}</h2>
            <PromoLinksItemUl>{upgrade_listed_links}</PromoLinksItemUl>
          </Col>
        </Row>
      </>
    );
  }

  const handleDelete = async (id) => {
    const response = await api.delete(endpoints.deletePromo + '/' + id).then((response) => {
      if (response.status === 204) {
        customSuccessNotify('Promo eliminata con successo');
        setToRefreshTable(true);
      }
    }).catch((error) => {
      customWarningNotify('Errore durante l\'eliminazione della promo');
    });
  }

  const handleOpenCreate = () => {
    setShowCreateModal(true);
  }

  const handleCloseCreate = () => {
    setShowCreateModal(false);
  }

  const handleSubmit = async () => {
    const dataToSend = { ...newPromo };
    if (dataToSend.stripe_id != '') {
      dispatch(postPromo(dataToSend)).then((response) => {
        if (response.error) {
          customWarningNotify(response.error)
        }
        else {
          customSuccessNotify('Promo creata con successo');
          setToRefreshTable(true);
          setShowCreateModal(false);
          setNewPromo(defaultPromo);
        }
      })
    }
  }

  const updatePromoStatus = async (id, status, currentValue) => {
    const response = await api.put(endpoints.updatePromo + '/' + id, {
      [status]: currentValue === 1 ? 0 : 1
    }).then((response) => {
      if (response.status === 200) {
        customSuccessNotify('Promo aggiornata con successo');
        setToRefreshTable(true);
      }
      else {
        customWarningNotify('Errore durante l\'aggiornamento della promo');
      }
    })
  }

  useEffect(() => {
    if (newPromo.duration == 2) {
      setShowPromoDurationMonths(true);
    }
    else {
      setShowPromoDurationMonths(false);
    }
  }, [newPromo.duration])

  useEffect(() => {
    const initialPayload = {
      params: globalFilters
    };

    dispatch(fetchListPromo(initialPayload));
  }, [dispatch]);

  useEffect(() => {

    if (toRefreshTable) {
      const initialPayload = {
        params: globalFilters
      };
      dispatch(fetchListPromo(initialPayload));
      setToRefreshTable(false);
    }

  }, [toRefreshTable]);

  useEffect(() => {

    switch (interactedItem.action) {
      case 'showLinks': {
        setModalProperties({
          title: 'Elenco link promo',
          content: showLinks(interactedItem.item.promoLinks)
        }
        );
        handleOpen();
        break
      }
      case 'delete': {
        if (interactedItem.item?.id > 0) {
          handleDelete(interactedItem.item.id);
        }
        break;
      }
      case 'togglePaypal': {
        if (interactedItem.item?.id > 0) {
          updatePromoStatus(interactedItem.item.id, 'paypal_enabled', interactedItem.item.paypal_enabled);
        }
        break;
      }
      case 'toggleWireTransfer': {
        if (interactedItem.item?.id > 0) {
          updatePromoStatus(interactedItem.item.id, 'wire_transfer_enabled', interactedItem.item.wire_transfer_enabled);
        }
        break;
      }
    }
  }, [interactedItem]);

  useEffect(() => {
    if (newPromo.plans.company_m == 1 || newPromo.plans.company_s == 1 || newPromo.plans.company_a == 1) {
      setShowCompanyPlus(true);
    }
    else {
      setShowCompanyPlus(false);
    }
  }, [newPromo.plans])

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Elenco Promo</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <CardTitleWrap>
                  <Row className='align-items-center justify-content-between'>
                    <Col xs={12} lg={4}>
                      <p className="page-subtitle">Numero elementi: {pagination?.total_count}</p>
                    </Col>
                    <Col lg={4} style={{ textAlign: 'right' }}>
                      <Button onClick={() => handleOpenCreate()} variant="outline-primary" size='sm'>
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        <span>Aggiungi promo</span>
                      </Button>
                    </Col>
                  </Row>
                </CardTitleWrap>
                {loading === 'loading' ? (
                  <div className="text-center">
                    <TableSpinner animation="border" />
                  </div>
                ) : (
                  <>
                    <Table responsive striped bordered>
                      <thead>
                        <tr>
                          {tableLabels.map((label) => {
                            return <th>{label}</th>
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {listPromo && loading === 'idle' && listPromo.length > 0 && (
                          listPromo.map((elem) => {
                            return (<PromoItemList item={elem} selectedItem={setInteractedItem} />)
                          })
                        ) || (
                            <tr>
                              <td colSpan={tableLabels.length} className='text-start'>Nessun elemento trovato</td>
                            </tr>
                          )}
                      </tbody>
                    </Table>
                    {listPromo && listPromo.length > 0 && (
                      <PaginationTable pagination={pagination} filters={globalFilters} action={fetchListPromo} />
                    )}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleClose} size="xl">

        <Modal.Header closeButton>
          <Modal.Title>{modalProperties.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {modalProperties.content}
        </Modal.Body>

      </Modal>

      <Modal show={showCreateModal} onHide={handleCloseCreate} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Crea link promo</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form autoComplete='off'>
            <Container>
              <Row>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group controlId={'name'}>
                        <Form.Label>Nome promo</Form.Label>
                        <Form.Control
                          name={'name'}
                          value={newPromo.name}
                          type="text"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId={'stripe_id'}>
                        <Form.Label>Codice promo</Form.Label>
                        <Form.Control
                          name={'stripe_id'}
                          value={newPromo.stripe_id}
                          type="text"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs={12} md={6}>
                      <Form.Group controlId={'activation_cost'}>
                        <Form.Label>Sconto (€)</Form.Label>
                        <Form.Control
                          name={'amount_off'}
                          value={newPromo.amount_off}
                          type="text"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId={'percent_off'}>
                        <Form.Label>Percentuale sconto</Form.Label>
                        <Form.Control
                          name={'percent_off'}
                          value={newPromo.percent_off}
                          type="text"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId={'activation_cost'}>
                        <Form.Label>Costo di attivazione (IVA inclusa)</Form.Label>
                        <Form.Control
                          name={'activation_cost'}
                          value={newPromo.activation_cost}
                          type="text"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col xs={12} md={6}>
                      <Form.Group controlId={'duration'}>
                        <Form.Label>Durata dello sconto</Form.Label>
                        <Form.Select
                          name={'duration'}
                          value={newPromo.duration}
                          onChange={handleChange}
                        >
                          <option value={1}>Solo primo pagamento</option>
                          <option value={2}>N mesi</option>
                          <option value={3}>Per sempre</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId={'duration_in_months'} className={showPromoDurationMonths ? 'd-block' : 'd-none'}>
                        <Form.Label>Numero mesi</Form.Label>
                        <Form.Select
                          name={'duration_in_months'}
                          value={newPromo.duration_in_months}
                          onChange={handleChange}
                          disabled={!showPromoDurationMonths}
                        >
                          <option value={1}>1 mese</option>
                          <option value={2}>2 mesi</option>
                          <option value={3}>3 mesi</option>
                          <option value={4}>4 mesi</option>
                          <option value={5}>5 mesi</option>
                          <option value={6}>6 mesi</option>
                          <option value={7}>7 mesi</option>
                          <option value={8}>8 mesi</option>
                          <option value={9}>9 mesi</option>
                          <option value={10}>10 mesi</option>
                          <option value={11}>11 mesi</option>
                          <option value={12}>12 mesi</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs={12} md={4}>
                      <Form.Group controId={'paypal_enabled'}>
                        <Form.Check
                          name={'paypal_enabled'}
                          type="checkbox"
                          label="PayPal abilitato"
                          checked={newPromo.paypal_enabled == 1}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Group controlId={'wire_transfer_enabled'}>
                        <Form.Check
                          name={'wire_transfer_enabled'}
                          type="checkbox"
                          label="Bonifico abilitato"
                          checked={newPromo.wire_transfer_enabled == 1}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12}>
                      <h4 style={{ fontWeight: "normal" }}>Seleziona almeno un piano da collegare alla promo</h4>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs={12} md={4}>
                      <h4 className="mb-3">Lite</h4>
                      <Form.Group controlId={'lite_m'}>
                        <Form.Check
                          name={'plans[lite_m]'}
                          type="checkbox"
                          label="Mensile"
                          checked={newPromo.plans.lite_m == 1}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group controlId={'lite_s'}>
                        <Form.Check
                          name={'plans[lite_s]'}
                          type="checkbox"
                          label="Semestrale"
                          checked={newPromo.plans.lite_s == 1}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group controlId={'lite_a'}>
                        <Form.Check
                          name={'plans[lite_a]'}
                          type="checkbox"
                          label="Annuale"
                          checked={newPromo.plans.lite_a == 1}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                      <h4 className="mb-3">Pro</h4>
                      <Form.Group controlId={'pro_m'}>
                        <Form.Check
                          name={'plans[pro_m]'}
                          type="checkbox"
                          label="Mensile"
                          checked={newPromo.plans.pro_m == 1}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group controlId={'pro_s'}>
                        <Form.Check
                          name={'plans[pro_s]'}
                          type="checkbox"
                          label="Semestrale"
                          checked={newPromo.plans.pro_s == 1}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group controlId={'pro_a'}>
                        <Form.Check
                          name={'plans[pro_a]'}
                          type="checkbox"
                          label="Annuale"
                          checked={newPromo.plans.pro_a == 1}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                      <h4 className="mb-3">Company</h4>
                      <Form.Group controlId={'company_m'}>
                        <Form.Check
                          name={'plans[company_m]'}
                          type="checkbox"
                          label="Mensile"
                          checked={newPromo.plans.company_m == 1}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group controlId={'company_s'}>
                        <Form.Check
                          name={'plans[company_s]'}
                          type="checkbox"
                          label="Semestrale"
                          checked={newPromo.plans.company_s == 1}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group controlId={'company_a'}>
                        <Form.Check
                          name={'plans[company_a]'}
                          type="checkbox"
                          label="Annuale"
                          checked={newPromo.plans.company_a == 1}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className={`mt-4${showCompanyPlus ? ' d-block' : ' d-none'}`}>
                    <Form.Group controlId={'invitations_bonus'}>
                      <Form.Label>Tipologia company+</Form.Label>
                      <Form.Select
                        name={'invitations_bonus'}
                        value={newPromo.invitations_bonus}
                        onChange={handleChange}
                      >
                        <option>Seleziona tipologia company+</option>
                        <option value="2000">Company+ 2000</option>
                        <option value="2500">Company+ 2500</option>

                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Col xs={12} className='my-3 text-end'>
                      <Button onClick={() => handleSubmit()} size="sm" variant={'success'}>
                        <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

PromoList.propTypes = {
  onSubmit: PropTypes.func,
};

export default PromoList;